import React from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

export default function PageLayout({header, firstColumn, children}) {
  return (
    <div>
        <Navbar/>
        <div className="mt-10 px-10">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 md:ml-24 ">
    
                <div className="md:col-span-1 relative mb-8">
                    {header}

                    {firstColumn}
                </div>
                {children}
            </div>
        </div>
        <Footer/>
    </div>
  );
};
