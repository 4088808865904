import React from "react";

const BatchSelector = ({ selectedBatch, onBatchSelect, setToNextStep }) => {
  // Hardcoded batch options for each course
  const courses = [
    {
      name: "Recitation Correction Basic",
      pricing: 2000,
      batches: [
        {
          name: "Monday - Thursday 5:30 AM",
          telegramLink: "https://t.me/+isI0wfuPaxtkNDNl"
        }
      ]
    },
    {
      name: "Recitation Correction Intermediary",
      pricing: 2000,
      batches: [
        {
          name: "Friday - Wednesday 5:30 AM",
          telegramLink: "https://t.me/+QqAOU75bnO5jMWY1"
        },
        {
          name: "Saturday - Wednesday 3:30 PM",
          telegramLink: "https://t.me/+UAc-ICysoOw5YjBl"
        },
        {
          name: "Tuesday - Thursday 9:30 AM",
          telegramLink: "https://t.me/+8n6VE2Db40Q1ZGJl"
        }
      ]
    },
    {
      name: "Hifz - Juz 30th",
      pricing: 1000,
      batches: [
        {
          name: "Sunday - Wednesday 4:50 PM",
          telegramLink: "https://t.me/+lcjGHs4NL9kyOWI1"
        }
      ]
    },
    {
      name: "Tajweed Theory (Basic to Advance)",
      pricing: 1000,
      batches: [
        {
          name: "Monday 4:45 PM, Tuesday 5:30 AM, Sunday 6:30 AM",
          telegramLink: "https://t.me/+6Kda61Bmrf01NTc1"
        }
      ]
    }
  ];
  // Function to handle batch selection
  const handleBatchSelection = (batch, course) => {
    onBatchSelect(batch, course);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-3xl font-semibold mt-6 mb-10 p-3">Select a Course and Batch</h2>

      {/* Render all courses and batches at once */}
      <div className="space-y-10">
        {courses.map((course, index) => (
          <div key={index} className="text-center">
            <h3 className="text-xl font-medium mb-4">{course.name}</h3>
            <div className="space-y-4">
              {course.batches.map((batch, batchIndex) => (
                <button
                  key={batchIndex}
                  onClick={() => handleBatchSelection(batch, course)}
                  className={`min-w-80 p-3 border border-pink-300 rounded-md transition-all 
                    ${selectedBatch.name === batch.name ? 'bg-pink-300' : 'bg-pink-100 hover:bg-pink-200'}
                  `}
                >
                  {batch.name}
                </button>
              ))}
            </div>
           
          </div>
        ))}
      </div>

      <p className="mt-6 text-sm text-gray-600">
        **Students of every batch can join and listen to the classes of other batches also.
      </p>

      <button className="p-3 mt-6 bg-gray-100 border-2 border-customCoral font-semibold rounded-md hover:bg-hoverCoral transition-colors"
            onClick={() => setToNextStep(2)}
      >
        Continue
    </button>
    </div>
  );
};

export default BatchSelector;
