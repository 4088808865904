import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ReviewCard from '../Components/ReviewCard'
import Slider from 'react-slick';

export default function Home() {

  function Arrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "#f8c5c4", color: "#f8c5c4", width: "20px" }}
        onClick={onClick}
      />
    );
  }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Show 2 cards at a time
        slidesToScroll: 1,
        prevArrow: <Arrow/>,
        nextArrow: <Arrow/>,
        centerMode: true,
        responsive: [
            {
                breakpoint: 870, // Breakpoint at 768px for mobile view
                settings: {
                  slidesToShow: 1, // Show 1 card at a time on smaller screens
                  slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1270, // Breakpoint at 768px for mobile view
                settings: {
                  slidesToShow: 2, // Show 1 card at a time on smaller screens
                  slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1512, // Breakpoint at 768px for mobile view
                settings: {
                  slidesToShow: 3, // Show 1 card at a time on smaller screens
                  slidesToScroll: 1,
                },
            },
        ],
      };

    return (

        <div class="bg-pink-50">
            <Navbar/>

            <div class="max-w-8xl mx-auto px-10 items-center">
                <div class="grid grid-cols-1 xl:grid-cols-[5fr_3fr] gap-10">
                    <div class="md:pl-16 w-full justify-center text-[#783b83] min-w-3xl md:text-lg text-md flex flex-col gap-10 items-center">
                        <div class="flex justify-center">
                            <img src="/logo.png" alt="Quran Logo"/>
                        </div>

                        <h1 className="text-3xl font-semibold">Let’s Learn Quran Together</h1>
                        <p class="text-left">
                            Our journey is a lifetime journey with the Quran focused on <strong>"women's Quran education"</strong>. The Prophet (Peace be upon him) said: 
                        </p>
                        
                        <blockquote class="text-[#620622] text-2xl font-semibold">
                            “The best of you are those who learn the Quran and teach it.” 
                            <span class="block font-normal text-[#620622]">(Sahih al Bukhari)</span>
                        </blockquote>

                        <p class="text-left">
                            We believe everyone should have the facility to read, learn and excel in Quran from the scratch. To facilitate the journey of Quran:
                        </p>
                        <div className="w-fit">
                            <ul class="font-bold text-left list-['#']">
                                <li className="mb-1 p-1">We take students whole year round</li>
                                <li className="mb-1 p-1">Our students can start from scratch up to advanced level</li>
                                <li className="mb-1 p-1">Our students can have the training of being a teacher and teach also.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="h-full italic rounded-xl bg-[url('./assets/images/background2.png')] bg-cover flex-col bg-center bg-no-repeat mb-8 min-h-[759px] md:ml-16 ml-0 md:mr-16">
                        <div class="flex text-left">
                            <h2 class="mt-8 text-2xl font-bold mb-4 pl-8 pr-24 bg-[#f49e9d] ">Our Platform</h2>
                        </div>
                        <div class="px-8">
                            <ul class="text-xl text-left font-semibold text-[#620622] space-y-2">
                                <li>- Recitation Correction</li>
                                <li>- Tajweed Theory</li>
                                <li>- Memorization with meaning</li>
                                <li>- Teachers' Training</li>
                                <li>- Practice Class</li>
                                <li>- Seerah</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Review Cards Section */}
                <div className="my-12 md:px-16 flex flex-col" id="reviews">
                    <h2 className="text-3xl font-semibold text-center text-[#620622] mb-8 p-4 w-fit flex self-center">Student Reviews</h2>
                    <Slider {...settings}>
                        <ReviewCard
                            name="Fatima A."
                            review="Alhamdulillah Robbil Alamin. The light and blessings of Allah's book Al Quran 
                                is experienced in this dars of quran. When I start the day with Quran, the rest of 
                                the day fills with Rahmah and Barakah."
                            rating={5}
                        />
                        <ReviewCard
                            name="Aisha M."
                            review="When life fades out, Allah gives it color. This color is nowhere except in the pages of quran. 
                                I started my walk along with quran newly with ustada. I felt positivity I never felt before."
                            rating={5}
                        />
                        <ReviewCard
                            name="Zainab K."
                            review="In this Ramadan, feeling the good fruits of sticking to the quran dars the whole year. 
                                        It's only the will of Allah. Quran recitation is making this Ramadan more satisfactory 
                                        than ever before. All praise to Allah Almighty."
                            rating={5}
                        />
                         <ReviewCard
                            name="Zainab K."
                            review="“With the help of ustada, I’m getting the chance to correct the suras I usually recite in 
                                        prayer. So I can concentrate more in salah now. Alhamdulillah.”"
                            rating={5}
                        />
                    </Slider>
                </div>
            </div>
            <Footer/>
        </div>
  )
}
