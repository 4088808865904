import React from 'react'

export default function Header({title, children}) {
  return (
    <header class="text-center">
        <h1 class="text-3xl font-semibold bg-[#f8c5c4] italic py-2 mb-4">{title}</h1>
        {children}
    </header>
  )
}
