import React from 'react'

export default function Footer() {
  return (
    <div className="mt-6 border-t border-[#783b83] px-20 py-2 inline-block">
        <p className="text-xs text-gray-900 font-semibold">
            Contact Us
        </p>
        <div className="mt-1 space-x-4 flex justify-center">
            <a href="https://t.me/+YtqjMYKInNw2NGU1">
                <img src="/telegram_logo.svg" alt="Telegram" className="w-8 h-8"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100063740185932&mibextid=যব্বক্বল">
                <img src="/facebook_logo.svg" alt="Facebook" className="w-8 h-8"/>
            </a>
            <a href="https://chat.whatsapp.com/JkCZ3RLb0tB7l7de0XYZ0e">
                <img src="/whatsapp_logo.svg" alt="WhatsApp" className="w-8 h-8"/>
            </a>
        </div>
    </div>
  )
}
