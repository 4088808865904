import React from 'react';
import Navbar from '../Components/Navbar'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import InfoBox from '../Components/InfoBox'
import PageLayout from '../Layouts/PageLayout';

export default function CourseDetails() {
  return (
   <PageLayout
        header={
            <Header title="Course Details">
                <h2 class="text-xl bg-[#f79492] py-1 border-b border-l border-gray-500">
                    Our Platform
                </h2>
            </Header>
        }
        firstColumn={
            <div>
                <article className="grid grid-cols-2 justify-between relative">
                    <span className="absolute left-10 top-0 h-full w-[2px] border-1 bg-gray-300 -z-10"></span>
                    <span className="absolute right-10 top-0 h-full w-[2px] bg-gray-300"></span>
                    <div className="mt-4">
                        <InfoBox>
                            <p>Memorization</p>
                            <p>Arabic Word Meaning</p>
                        </InfoBox>
                    </div>
                    <div className="flex flex-col gap-2 relative">
                        <span className="absolute left-10 top-0 h-full w-[2px] bg-gray-300 -z-10"></span>
                        <InfoBox style="self-end mt-4">
                            <p>Tajweed Theory</p>
                        </InfoBox>
                        <InfoBox style="self-start">
                            <p>Tafseer</p>
                            <p>Seerah</p>
                        </InfoBox>
                        <InfoBox style="self-end">
                            <p>Practice Class</p>
                            <p>Arabic Handwriting</p>
                        </InfoBox>
                        <InfoBox style="self-start">
                            <p>Teacher's Training</p>
                        </InfoBox>
                    </div>
                </article>
            </div>
        }
    >
        <div className="md:col-span-2 grid">
            <div className="full-h grid md:grid-cols-2 gap-6">
                <div className="flex flex-col sm:items-center">
                    <div className="flex flex-col gap-10 text-left text-xl text-[#620622]">
                        <h3 class="font-semibold text-gray-950 bg-[#f8c5c4] p-1 px-3 w-fit">Major Courses:</h3>
                        <div className="flex flex-col gap-20">
                            <div>
                                <div className="font-bold">Recitation Correction :</div>
                                <div className="font-normal"> - Basic to Advanced</div>
                            </div>
                            <div>
                                <div className="font-bold">Tajweed Theory :</div>
                                <div className="font-normal"> - Basic to Advanced</div>
                            </div>
                            <div>
                                <div className="font-bold">Hifz:</div>
                                <div className="font-normal">- from Surah Fatiha (juz Amma)</div>
                                <div className="font-normal">- Learning meaning of Quranic Arabic</div>

                            </div>
                            <div>
                                <div className="font-bold">Teacher's Training</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-8 sm:items-center">
                    <div className="flex flex-col gap-10 text-left text-xl">
                        <h3 class="font-semibold text-gray-950 bg-[#f8c5c4] p-1 px-3 w-fit">Weekly Practice Classes</h3>
                        <div className="flex flex-col gap-5">
                            <h3 className="font-semibold text-gray-950 bg-[#f8c5c4] p-1 px-3 w-fit">Special Classes on</h3>
                            <ul className="list-['-'] ml-5  text-[#620622]">
                                <li className="mt-2 p-1">Arabic Handwriting</li>
                                <li className="mt-2 p-1">Tasfir</li>
                                <li className="mt-2 p-1">Seerah</li>
                                <li className="mt-2 p-1">Ramadan Preparation</li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-5 mt-6 text-left text-xl text-[#620622]">
                            <header className="flex items-center">
                                <img src="/Clock.svg" className="w-14 h-auto mr-4"/>
                                <h3 class="font-semibold text-gray-950 bg-[#f8c5c4] p-2 px-3 w-fit">
                                        Batch Times
                                </h3>
                            </header>
                            <ul className="list-['-'] ml-5 text-[#620622] font-semibold">
                                <li className="p-2"> Saturday and Wednesday - 3:30 PM</li>
                                <li className="p-2"> Tuesday and Thursday - 9:30 AM</li>
                                <li className="p-2"> Wednesday and Friday - 5:00 AM</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
  );
};
