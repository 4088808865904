import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <nav class="bg-customCoral py-2 mb-6 xl:text-xl text-lg">
        <ul class="flex md:flex-row flex-col justify-center items-center lg:gap-20 gap-12 font-semibold space-around">
            <li><Link to="/"><img src="/logo.png" className="h-12 w-12"/></Link></li>
            <li><Link to="/course-details" class="hover:text-[#aa6867]">Course Details</Link></li>
            <li><a href="/#reviews" class="hover:text-[#aa6867]">Reviews</a></li>
            <li><Link to="/admission" class="hover:text-[#aa6867]">Admission</Link></li>
            <li><Link to="/teachers-panel" class="hover:text-[#aa6867]">Teacher’s Panel</Link></li>
            <li><Link to="/donation" class="hover:text-[#aa6867]">Donation</Link></li>
        </ul>
    </nav>
  )
}

