import React from 'react'
import Header from '../Components/Header'
import PageLayout from '../Layouts/PageLayout';


export default function Donation() {
    return (
        <PageLayout header={
            <Header title="Donation">
                <p className="mt-2 text-lg text-[#620622] italic">A way to get closer to Allah</p>
            </Header>
        }
        firstColumn={
            <div className="flex flex-col items-center gap-10 mt-8">
                <img className="w-[250px] h-[250px]" src="/donation-thumbnail.png" alt="Hand holding heart"/>
                <div className="text-justify text-[#620622]">
                    <p className="text-2xl font-semibold italic">
                    “Those who give, out of their own possessions, by night and by day, in private and in public will have their reward with their Lord.”
                    </p>
                    <p className="text-sm italic mb-8">Surah Baqarah 2:274</p>
                </div>
            </div>
        }
        >
            <div className="md:col-span-2 grid text-[#620622] text-2xl justify-center italic">
                <div className="full-h text-left flex flex-col gap-4">
                    <h3 className="font-semibold">With your donation we provide:</h3>
                        <ul className="list-['-'] list-inside mt-4">
                            <li className="mb-4">
                                <span className="font-semibold p-1">For the students of quran </span> 
                                  and their family who cannot afford basic needs.
                            </li>
                            <li className="mb-4">
                            <span className="font-semibold p-1">Patients who cannot afford </span> 
                            their treatment. Sometimes it becomes life-saving treatment.
                            </li>
                        </ul>
                    <p className="mt-4 bg-rose-200 p-2 font-semibold text-center">You can give Zakat also!</p>
                </div>
            
                <div className="mt-8 flex justify-center space-x-8 font-semibold">
                    <button className="bg-rose-200 py-1 px-4 rounded shadow-lg hover:bg-rose-400 max-h-fit">
                        you can donate
                    </button>
                    <button className="bg-rose-200 py-1 px-4 rounded shadow-lg hover:bg-rose-400 max-h-fit">
                        One Time
                    </button>
                    <p className="py-2 text-lg">or</p>
                    <button className="bg-rose-200 py-1 px-4 rounded shadow-lg hover:bg-rose-400 max-h-fit">
                        Monthly
                    </button>
                </div>

                <div className="mt-8 flex justify-center space-x-8 font-semibold">
                    <button className="bg-rose-200 ml-4 p-4 rounded shadow-lg hover:bg-rose-400 max-h-20">
                        Donation Mode
                    </button>
                    <div className="text-left not-italic flex flex-col gap-6">
                        <p className="mt-2 font-semibold">Bkash: <span className="font-normal">0712288520</span><br/></p>
                        <div>
                            <h4 className="font-semibold">Bank Account:</h4>
                            <p className="font-normal">
                                Islami Bank, Shyamoli branch<br />
                                Account no: 20502090203400107<br />
                                Account holder: Fariha Tabassum
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
  };
