import React from 'react'
import Header from '../Components/Header'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import PageLayout from '../Layouts/PageLayout'


export default function TeachersPanel() {
  return (
    <PageLayout 
        header={
            <Header title="Teacher's Panel">
                <img src="/outline_in_hijab.svg" alt="Principal" class="mx-auto my-4 w-32 h-32 rounded-full"/>
                <h2 class="text-xl font-bold">Haajar Khatun Maliha</h2>
                <p class="text-sm">Principal</p>
            </Header>
        }
        firstColumn={
            <div class="mt-6 text-lg">
                <h3 class="font-semibold text-left">About Our Principal...</h3>
                <article className="text-justify">
                    <p className='mt-2'>
                        She is the honourable principal of "Let's Learn Quran Together" academy.
                    </p>
                    <p className="mt-2">
                        Ustada Haajar studied & specialized on quran recitation under the guidance of teachers and shaikhas from Bangladesh, Egypt & Sudan.
                        She got ijazah on the kiraat "Hafs an Asim", studying more than one kiraat currently.
                    </p>
                    <p className="mt-2">
                        Ustada Haajar has been teaching quran recitation both in Bangladesh & Australia since last 10 years. She has taught in several online & offline international platforms like "Work for hereafter", "Dar al Quran" etc.
                        Not only does she teach the students of Quran, but also takes training courses for Quran teachers.
                    </p >
                    <p className="mt-2">
                        Ustada Haajar is currently focusing on her own studies on the Quran and working for establishing a sisters' Quran Learning Platform.
                    </p>
                    <p className="mt-2">
                        May Allah Bless her in both Duniya and Akhirah.                        
                    </p>
                </article>
            </div>
        }
    >
        <div className="md:col-span-2 grid text-xl">
            <div className="full-h grid md:grid-cols-2 grid-cols-1 gap-6">
                <div className="flex flex-col justify-evenly md:items-center">
                    <div className="flex flex-col gap-10 text-left">
                        <h3 class="font-semibold text-2xl text-[#6b3b6d]">Instructors</h3>
                        <div>
                            <div className="font-bold">Zakia Tasneem</div>
                            <div className="font-normal">Instructor of Tajweed Theory</div>
                        </div>
                        <div>
                            <div className="font-bold">Amena binte Abdullah</div>
                            <div className="font-normal">Instructor</div>
                        </div>
                        <div>
                            <div className="font-bold">Maryam Sultana</div>
                            <div className="font-normal">Instructor</div>
                        </div>
                        <div>
                            <div className="font-bold">Farhana Ahmed</div>
                            <div className="font-normal">Instructor</div>
                        </div>
                        <div>
                            <div className="font-bold">Atifun Ifsha</div>
                            <div className="font-normal">Instructor</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-evenly mt-4">
                    <div className="flex flex-col gap-10 text-left">
                        <h3 class="font-semibold text-2xl text-[#6b3b6d]">Specialities of Our Platform</h3>
                        <div className="flex items-start space-x-2">
                            <span class="w-4 h-4 rounded-full bg-[#6b3b6d] mt-1"></span>
                            <span>Each student is given time separately.</span>
                        </div>
                        <div>
                            <h3 className="font-semibold text-[#6b3b6d]">
                                <span class="w-4 h-4 rounded-full bg-[#6b3b6d] mt-1 inline-block mr-2"></span>
                                Practice Classes
                            </h3>
                            <span>So that a student can practice the given corrections further.</span>
                        </div>
                        <div>
                            <h3 className="font-semibold text-[#6b3b6d]">
                                <span class="w-4 h-4 rounded-full bg-[#6b3b6d] mt-1 inline-block mr-2"></span>
                                    Teachers' Training
                            </h3>
                            <span> A student with excellent results can apply for and have training for being a teacher.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </PageLayout>
  )
}
