import React, { useState } from "react";

function AdmissionForm({ batch, course, setToNextStep }) {
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNo: "",
    studyingInAcademy: "",
    heardFrom: "",
    referrerName: "",
    paymentMode: "",
    trxId: "",
    paymentAmount: "",
    screenshot: null,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, screenshot: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //console.log('Trying to send req');
      const response = await fetch('https://quran-learning-platform-server.onrender.com/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Form submitted successfully");
      } else {
        console.error("Form submission failed:");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setToNextStep(3);
  };

  return (
    <div className="max-w-6xl mx-auto p-8 rounded-lg shadow-lg text-left">
      <h1 className="text-3xl font-semibold text-center mb-8 text-black">Admission Form</h1>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-12">
        
        {/* Left Column */}
        <div className="space-y-8">
          <div>
            <label className="block text-lg font-medium text-black mb-2">Full Name:</label>
            <input
              required
              type="text"
              name="fullName"
              autoFocus={true}
              value={formData.fullName}
              onChange={handleChange}
              className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
            />
          </div>
          <div>
            <label className="block text-lg font-medium text-black mb-2">Mobile No:</label>
            <input
              required
              type="text"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
            />
          </div>
          <div>
            <label className="block text-lg font-medium text-black mb-2">Are you studying in any other Islamic academy?</label>
            <input
              required
              type="text"
              name="studyingInAcademy"
              value={formData.studyingInAcademy}
              onChange={handleChange}
              className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
            />
          </div>
          <div>
            <label className="block text-lg font-medium text-black mb-2">How did you find out about "Let’s Learn Quran Together"? <span className="text-red-500">*</span></label>
            <div className="flex flex-col space-y-2 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="heardFrom"
                  value="Facebook Page"
                  checked={formData.heardFrom === "Facebook Page"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">Facebook Page</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="heardFrom"
                  value="Google Search"
                  checked={formData.heardFrom === "Google Search"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">Google Search</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="heardFrom"
                  value="Website"
                  checked={formData.heardFrom === "Website"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">Website</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="heardFrom"
                  value="From other student"
                  checked={formData.heardFrom === "From other student"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">From other student</span>
              </label>
            </div>
          </div>
          
          {formData.heardFrom === "From other student" && (
            <div>
              <label className="block text-lg font-medium text-black mb-2">If someone referred you, mention name:</label>
              <input
                type="text"
                name="referrerName"
                value={formData.referrerName}
                onChange={handleChange}
                className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
              />
            </div>
          )}

          {/* Payment Details Text */}
          <div className="p-4 bg-pink-100 rounded-lg mt-8 border-2 border-customCoral">
            <p className="text-lg text-black">
              The monthly payment for {course.name} is {course.pricing} BDT.
            </p>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-8">
          {/* Account Details */}
          <div className="p-6 bg-pink-100 rounded-lg border-2 border-customCoral">
            <h2 className="text-xl font-semibold text-black mb-4">Account Details:</h2>
            <p className="text-lg font-medium text-black">Bank:</p>
            <p className="text-lg text-black">Islamic Bank, Shyamoli Branch</p>
            <p className="text-lg text-black">Account No: 20502090203400107</p>
            <p className="text-lg text-black">Account Holder: Fariha Tabassum</p>
            <p className="text-lg font-medium text-black mt-4">Bkash:</p>
            <p className="text-lg text-black">Account No: 01712288520</p>
          </div>

          <div>
            <label className="block text-lg font-medium text-black mb-2">Please Select Payment Mode: <span className="text-red-500">*</span></label>
            <div className="flex flex-col space-y-2 mt-2">
              <label className="flex items-center space-x-2">
                <input
                  required
                  type="radio"
                  name="paymentMode"
                  value="Bank"
                  checked={formData.paymentMode === "Bank"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">Bank</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="paymentMode"
                  value="Bkash"
                  checked={formData.paymentMode === "Bkash"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">Bkash</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="paymentMode"
                  value="Nagad"
                  checked={formData.paymentMode === "Nagad"}
                  onChange={handleChange}
                  className="h-5 w-5"
                />
                <span className="text-lg text-black">Nagad</span>
              </label>
            </div>
          </div>

          {/* Conditional Rendering based on paymentMode */}
          {formData.paymentMode === "Bank" && (
            <div>
              <label className="block text-lg font-medium text-black mb-2">Upload Screenshot</label>
              <input
                required
                type="file"
                name="screenshot"
                onChange={handleFileChange}
                className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
              />
            </div>
          )}
          {(formData.paymentMode === "Bkash" || formData.paymentMode === "Nagad") && (
            <div>
              <label className="block text-lg font-medium text-black mb-2">Trx ID:</label>
              <input
                required
                type="text"
                name="trxId"
                value={formData.trxId}
                onChange={handleChange}
                className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
              />
            </div>
          )}

          <div>
            <label className="block text-lg font-medium text-black mb-2">Payment Amount:</label>
            <input
              required
              type="text"
              name="paymentAmount"
              value={formData.paymentAmount}
              onChange={handleChange}
              className="w-full border-b border-dotted border-black bg-transparent focus:outline-none text-lg"
            />
          </div>
          <button className="p-3 mt-6 bg-gray-100 border-2 border-customCoral font-semibold rounded-lg hover:bg-hoverCoral transition-colors"
            onClick={() => handleSubmit}
            >
                Submit
            </button>
        </div>
        
      </form>
    </div>
  );
}

export default AdmissionForm;
