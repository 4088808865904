import React from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

export default function Reviews() {
  return (
    <div>
        <Navbar/>
            <div className="min-h-screen bg-gray-100 py-8">
                <div className="max-w-7xl mx-auto p-4">
                    <h1 className="text-3xl font-semibold text-center text-gray-700 mb-12">
                    Reviews
                    </h1>

                    {/* Top Review Section */}
                    <div className="flex justify-between mb-8">
                        <div className="w-1/3 p-4">
                            <p className="italic text-gray-600 mb-4">
                            “There is a lot to learn from quran dars. We feel lazy preparing our lessons, 
                            but ustada is never tired or lazy to correct us again. I get inspired seeing 
                            her beautiful patience! We should also be so patient while preparing for the class.”
                            </p>
                            <p className="font-semibold text-gray-700">- Student of recitation class</p>
                        </div>

                    <div className="w-1/3 p-4">
                        <p className="italic text-gray-600 mb-4">
                        “Alhamdulillah Robbil Alamin. The light and blessings of Allah's book Al Quran 
                        is experienced in this dars of quran. When I start the day with Quran, the rest of 
                        the day fills with Rahmah and Barakah.”
                        </p>
                        <p className="font-semibold text-gray-700">- Student of class</p>
                    </div>

                    <div className="w-1/3 p-4">
                        <p className="italic text-gray-600 mb-4">
                        “When life fades out, Allah gives it color. This color is nowhere except in the pages of quran. 
                        I started my walk along with quran newly with ustada. I felt positivity I never felt before.”
                        </p>
                        <p className="font-semibold text-gray-700">- Student of quran</p>
                    </div>
                </div>

                {/* Diamond-shaped Reviews */}
                <div className="grid grid-cols-2 gap-6">
                    <div className="relative transform rotate-45 bg-pink-100 shadow-lg p-6">
                        <div className="transform -rotate-45">
                            <p className="italic text-gray-700 mb-4">
                                “In this Ramadan, feeling the good fruits of sticking to the quran dars the whole year. 
                                It's only the will of Allah. Quran recitation is making this Ramadan more satisfactory 
                                than ever before. All praise to Allah Almighty.”
                            </p>
                            <p className="font-semibold text-gray-700">- Student of recitation class</p>
                        </div>
                    </div>

                    <div className="relative transform rotate-45 bg-pink-100 shadow-lg p-6">
                        <div className="transform -rotate-45">
                        <p className="italic text-gray-700 mb-4">
                            “Learnings from Today’s class: It’s the action of a believer that a believer is not hurt and 
                            also doesn’t hurt. A believer is not deceived and also doesn’t deceive.”
                        </p>
                        <p className="font-semibold text-gray-700">- Student of class</p>
                        </div>
                    </div>

                    <div className="relative transform rotate-45 bg-pink-100 shadow-lg p-6">
                        <div className="transform -rotate-45">
                        <p className="italic text-gray-700 mb-4">
                            “With the help of ustada, I’m getting the chance to correct the suras I usually recite in 
                            prayer. So I can concentrate more in salah now. Alhamdulillah.”
                        </p>
                        <p className="font-semibold text-gray-700">- Student of quran</p>
                        </div>
                    </div>

                    <div className="relative bg-[url('./assets/images/diamond.svg')] bg-contain shadow-lg p-6">
                        <div className="">
                        <p className="italic text-gray-700 mb-4">
                            “Through ‘Let’s Learn Quran Together’, I get to know Allah far more Alhamdulillah.”
                        </p>
                        <p className="font-semibold text-gray-700">- Student of quran</p>
                        </div>
                    </div>
                </div>

                {/* Contact Section */}
                <div className="mt-12 text-center">
                    <p className="text-gray-600">Contact Us</p>
                    <div className="flex justify-center mt-4">
                        <a href="#" className="mx-2">
                        <img src="/path-to-telegram-icon.png" alt="Telegram" className="w-6 h-6" />
                        </a>
                        <a href="#" className="mx-2">
                        <img src="/path-to-whatsapp-icon.png" alt="WhatsApp" className="w-6 h-6" />
                        </a>
                        <a href="#" className="mx-2">
                        <img src="/path-to-facebook-icon.png" alt="Facebook" className="w-6 h-6" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
