import React from 'react';

export default function ReviewCard({ name, review, rating }) {
  return (
    <div className="bg-[#f8c5c4] shadow-lg rounded-lg p-3 max-w-[60rem] max-h-[20rem] flex flex-col justify-center sm:mx-8 mx-2">
      <p className="text-lg font-semibold mb-2">{name}</p>
      <p className="text-gray-700 mb-4">"{review}"</p>
      <div className="flex items-center">
        {/* <div className="text-yellow-400 text-lg">
          {Array(rating).fill('⭐').map((star, index) => (
            <span key={index}>{star}</span>
          ))}
        </div> */}
      </div>
    </div>
  );
}
