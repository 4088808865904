import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import TeachersPanel from './Pages/TeachersPanel';
import Admission from './Pages/Admission';
import CourseDetails from './Pages/CourseDetails';
import Reviews from './Pages/Reviews';
import Donation from './Pages/Donation';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/course-details" element={<CourseDetails/>} />
          <Route path="/reviews" element={<Reviews/>} />
          <Route path="/teachers-panel" element={<TeachersPanel/>} />
          <Route path="/admission" element={<Admission/>} />
          <Route path="/donation" element={<Donation/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
