import React from 'react'
import Header from '../Components/Header'
import PageLayout from '../Layouts/PageLayout';
import {ArrowDownIcon} from '@heroicons/react/20/solid';
import AdmissionForm from './AdmissionComponents/AdmissionForm';
import BatchSelector from './AdmissionComponents/BatchSelector';
import { useState } from 'react';



export default function Admission() {

    const [selectedBatch, setSelectedBatch] = useState("");
    const [selectedCourse, setSelectedCourse] = useState("");
    const [step, setStep] = useState(1);

    // Function to handle batch selection
    const onBatchSelect = (batch, course) => {
      setSelectedBatch(batch);
      setSelectedCourse(course);
      console.log("Selected Batch: ", batch); // You can store or use this batch value here
      console.log("Selected Course: ", course); // You can store or use this batch value here

    };
  
    return (
        <PageLayout header={
            <Header title="Admission">
            </Header>
        }
        firstColumn={
            <div className="flex flex-col items-center gap-10 mt-8">
               <h1 className="m-4 font-bold text-2xl">Step 1: Batch Selection</h1>
               <ArrowDownIcon className="h-40 fill-hoverCoral"/>
               <h1 className="m-4 font-bold text-2xl">Step 2: Fill up the admission form</h1>
               <ArrowDownIcon className={`h-40 ${step === 2 ? "fill-hoverCoral": ""} `}/>
               <h1 className="m-4 font-bold text-2xl">Step 3: Payment(Bkash/Bank)</h1>
            </div>
        }
        >
           <div className="p-6 col-span-2 bg-customPink rounded-lg w-full">
            { 
                (step === 1) && 
                    <BatchSelector selectedCourse={selectedCourse} selectedBatch={selectedBatch} onBatchSelect={onBatchSelect} setToNextStep={setStep}/>
            }       
            {   
                (step === 2) && 
                    <AdmissionForm batch={selectedBatch} course={selectedCourse} setToNextStep={setStep}/>
            }
            {   
                (step === 3) && 
                    <div className="mt-10 flex flex-col justify-center items-center gap-10">
                        <h2 className="text-3xl font-semibold text-hoverCoral">Welcome to 'Let's Learn Quran Together!'</h2> 
                        <h2 className="text-xl font-medium"> Here is your batch link:</h2>
                        <article className="p-6 border-hoverCoral border-2 bg-pink-50">
                            {selectedBatch.telegramLink}
                        </article>
                        <h2 className="text-xl font-medium"> Or click here to join: </h2>
                        <a className="bg-[#620622] text-gray-50 p-3 rounded-lg" href={selectedBatch.telegramLink}>Telegram</a>
                    </div>
            }
            </div>        
            </PageLayout>
    );
  };
